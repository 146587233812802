<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="$t('nav.settings')"
                :pgIcon="'bx bx-wrench'"
                :refs="refs"
                :pgCurrent="pgCurrent"
                :addNew="false">
            </Breadcrumb>

            <div class="row">

                <Loading
                    v-if="pgLoading">
                </Loading>

                <div v-if="!pgLoading" class="col-8">
                    <div class="card">
                        <div class="card-body"><br/><br/><br/></div>
                    </div>
                </div>

                <div v-if="!pgLoading" class="col-4">
                    <CardStatus
                        :status="row.status"
                        v-on:statusChange="handleStatusChange">
                    </CardStatus>
                </div>
            </div>


            <Buttons
                v-if="!pgLoading"
                :btnLoading="btnLoading"
                :btnCurrent="btnCurrent"
                        
                v-on:submitClicked="handelSubmit"
                v-on:cancelClicked="handleCancel">
            </Buttons>

        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
    name: 'PriceSetting',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        Loading: () => import('@/components/Loading.vue'),
        CardStatus: () => import('@/components/CardStatus.vue'),
        Buttons: () => import('@/components/Buttons.vue')
    },
    data(){
        return {    
            // auth
            auth: {
                role: '',
                access_token: '',
            },

            // row
            row: {
                slug: '',
                status: 1,
            },
                
            msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
            btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
            methodType: (this.$route.params.id) ? 'PUT' : 'POST',
            pgLoading: (this.$route.params.id) ? true : false,
            pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
            btnLoading: false,
            
            refs: 'settings',
        }
    },
    mounted() {},
    created() {
        // AccessToken & Role
        if(localStorage.getItem('access_token')) {
            this.auth.access_token = localStorage.getItem('access_token');
        }
        if(localStorage.getItem('role')) {
            this.auth.role = localStorage.getItem('role');
        }

        if(this.$route.query.parent_id) {
            this.row.parent_id = this.$route.query.parent_id;
        }

        if(this.$route.query.slug) {
            this.row.slug = this.$route.query.slug;
        }

        if(this.$route.params.id) {
            this.fetchRow();
        }
    },
    methods: {
        
        // fetch Row
        fetchRow() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
                'locale': this.$i18n.locale
            };
            const options = {
                url: window.baseURL+'/'+this.refs+'/'+this.$route.params.id,
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;

                this.row.status = res.data.row.status;
            })
            .catch(err => {

                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }

            })
            .finally(() => {});
        },
            

        // handle edit Or New submit
        handelSubmit(){
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
                'Authorization': `Bearer ` + this.auth.access_token,
            };
            const config = { headers: { 'Content-Type': 'multipart/form-data' }};  
            const options = {
                url: (this.$route.params.id) ? window.baseURL+'/'+this.refs+'/'+this.$route.params.id : window.baseURL+'/'+this.refs,
                method: this.methodType,
                data: {

                    status: this.row.status
                }
            }
            this.axios(options, config)
            .then(() => {
                this.btnLoading = false;
                iziToast.success({
                    icon: 'bx bx-wink-smile',
                    title: '',
                    message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
                });
                
                if(this.$route.query.parent_id) {
                    this.$router.push({ path: '/'+this.refs+'/show?parent_id='+this.row.parent_id+'&slug='+this.row.slug})
                } else {
                    this.$router.push({ name: this.refs });
                }
            })
            .catch(err => {
                        
                // 403 Forbidden
                if(err.response && err.response.status == 401) {
                    this.clearLocalStorage();
                    this.$router.push({ name: 'login' });
                } else if(err.response && err.response.status == 403) {
                    this.$router.push({ name: 'forbidden' });
                } else {
                    this.btnLoading = false;
                    iziToast.warning({
                        icon: 'bx bx-angry',
                        title: '',
                        message: (err.response) ? err.response.data.message : ''+err
                    });
                }
            })
            .finally(() => {})

        },

          
        // clear Local Storage
        clearLocalStorage() {
            localStorage.removeItem('access_token');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('encrypt_id');
            localStorage.removeItem('role');
        },


        // Cancel
        handleCancel(){
            if(confirm('هل انت متأكد؟')) {
                this.$router.push({ name: this.refs });
            }
        },


        // handel Changes from child components 
        handleStatusChange(event) {
            this.row.status = event.status;
        },

    },
}
</script>
